<template>
  <b-card>
    <b-col cols="12" class=" border rounded ">
        <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center  " style="height: 30px">
                <label class="m-0">اطلاعات محصول :</label>
                <div v-if="!Variable" class="d-flex mx-25 align-items-center gap-2">
                  <input type="radio" class="form-check " :value="1" v-model="SelectedProductType">
                  <span class="text-secondary mx-1  ">ساده</span>
                </div>
                <div class="d-flex align-items-center gap-2 mx-25">
                  <input type="radio" class="form-check " :value="2" v-model="SelectedProductType">
                  <span class="text-secondary  mx-1 ">متغیر</span>
                </div>
              </div>
            </b-col>
        </b-row>
    </b-col>
    <b-col cols="12" class=" border rounded   ">
      <b-tabs
          class="my-25"
          vertical
          nav-wrapper-class="nav-vertical"
      >
        <b-tab v-if="ComputedProductType ===2" active title="ویژگی ها">
          <b-row>
            <b-col class="my-25" cols="12">
              <b-row>
                <b-col md="5" class="d-flex align-items-center ">
                  <v-select
                      :options="AllOptions"
                      class="w-100"
                      label="name"
                      placeholder="ویژگی ها" dir="rtl"
                      :reduce="name => name.optionId"
                      v-model="SelectedOptionId"
                  ></v-select>
                  <b-button @click="AddOption" variant="primary" class="mx-25">افزودن</b-button>
                </b-col>
                <b-col cols="12" >
                  <app-collapse
                      accordion
                      class="my-1"
                      type="border"
                  >

                    <app-collapse-item v-for="(item,idx) in SelectedOptions" :key="idx" :title="item.name" >
                      <div :id="`accordion${item.optionId}`" class=" border rounded">
                        <b-row class="p-25">
                          <b-col cols="12">
                            <v-select
                                :ref="`Category${item.optionId}`"
                                :options="item.optionValueList"
                                label="value"
                                autoscroll

                                multiple=""
                                placeholder="مقدار ها" dir="rtl"

                                @input="AddSingleValue($event,item.optionId)"
                            >
                              <template #option="{ value}">
                                <h6
                                    :class="{'text-secondary':$refs[`Category${item.optionId}`].value && $refs[`Category${item.optionId}`].value.includes(value)}"
                                    v-if="!value.includes('#')" style="margin: 0">{{ value }}</h6>
                                <div class=" my-25 shadow rounded-circle"
                                     :class="{'text-white':$refs[`Category${item.optionId}`].value && $refs[`Category${item.optionId}`].value.includes(value)}"
                                     v-else :style="`background-color:${value}`"
                                     style="margin: 0;width: 30px;height: 30px"></div>
                              </template>



                              <template #selected-option="{ value}">
                                <h6
                                    :class="{'text-secondary':$refs[`Category${item.optionId}`].value && $refs[`Category${item.optionId}`].value.includes(value)}"
                                    v-if="!value.includes('#')" style="margin: 0">{{ value }}</h6>
                                <div class=" my-25 shadow rounded-circle"
                                     :class="{'text-white':$refs[`Category${item.optionId}`].value && $refs[`Category${item.optionId}`].value.includes(value)}"
                                     v-else :style="`background-color:${value}`"
                                ></div>
                              </template>
                            </v-select>
                          </b-col>
                          <b-col md="12" class="my-25">
                            <b-button @click="RemoveOption(idx)" variant="danger" class="mx-25">حذف</b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </app-collapse-item>
                  </app-collapse>
                </b-col>
                <b-col cols="12">
                  <b-button v-if="SelectedOptions.length>0" @click="CollectVariables" variant="primary" class="mx-25">افزودن به متغیر ها</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-tab>

        <b-tab v-if="ComputedProductType ===2" title="متغیر ها">
          <b-row v-if="CollectedData!== null">
            <b-col md="12" class=" pb-25">
              <b-row>
                <b-col md="5" class="d-flex align-items-center">
                  <b-button variant="primary" @click="AddVariable">افزودن</b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class=" pb-25">
              <app-collapse
                  accordion
                  class="my-1"
                  type="border"
              >
                <app-collapse-item v-for="(item,idx) in VariableCounts" :key="item"  title="">
                      <template v-slot:header>
                        <div  class="w-100    d-flex align-items-center">
                          <small class="w-25">نام متغیر : </small>
                          <div @click.prevent @click.stop v-for="(item) in CollectedData" class="d-flex align-items-center gap-2 w-75">
                            <v-select
                                :ref="`FinalValue${idx}${item.ListId}`"
                                :key="idx"
                                class="w-50"
                                @click.stop
                                @input="CheckValues($event,idx,item.ListId)"
                                :options="item.CollectedData"
                                label="value"
                                placeholder="مقدار" dir="rtl"
                            >
                              <template #option="{ value}">
                                <h6 v-if="!value.includes('#')" style="margin: 0">{{ value }}</h6>
                                <div class=" my-25 shadow rounded-circle" v-else :style="`background-color:${value}`"
                                     style="margin: 0;width: 30px;height: 30px"></div>
                              </template>
                              <template #selected-option="{ value}">
                                <h6 v-if="!value.includes('#')" style="margin: 0">{{ value }}</h6>
                                <div class=" my-25 shadow rounded-circle" v-else :style="`background-color:${value}`"
                                     style="margin: 0;width: 20px;height: 20px"></div>
                              </template>
                            </v-select>
                            <input type="text" :ref="`listId${idx}${item.ListId}`" class="d-none" :value="item.ListId">

                          </div>
                          <small>
                            <div @click="DeleteVariable(idx)">
                              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
                            </div>
                          </small>

                        </div>
                      </template>

                    <b-col md="12">
                        <b-row>
                          <b-col md="6">
                            <input type="number" class="form-control" :ref="`Price${idx}`"
                                   placeholder="قیمت">

                          </b-col>
                          <b-col md="6">
                            <input type="number" class="form-control" :ref="`Qty${idx}`"
                                   placeholder="تعداد">

                          </b-col>

                        </b-row>
                    </b-col>
                </app-collapse-item>
              </app-collapse>
            </b-col>
            <b-col v-if="VariableCounts.length>0" md="12" class=" pb-25">
              <b-button variant="primary" @click="AddPrice">ثبت لیست قیمت</b-button>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <span class="text-secondary">لطفا ابتدا چند متغیر تعریف کنید</span>
            </b-col>
          </b-row>







        </b-tab>
        <b-tab  v-if="FinalPrice" title=" مدیا">
          <b-row >
            <b-col cols="12" class="my-2">
              <strong class="h4">ایجاد مدیا برای قیمت ها</strong>
            </b-col>
            <b-col cols="12" class="my-2">
              <div class="d-flex align-items-center ">
                <span>انتخاب قیمت برای ایجاد مدیا : </span>
                <b-dropdown
                    text="انتخاب"
                    class=" mx-2 "
                    variant="gradient-primary"
                >
                  <b-dropdown-item v-for="(item,idx) in FinalPrice" :key="idx"  @click="SetSelectedPrice(item,idx)">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center  flex-wrap">
                        <small v-for="(el,idx) in item.optionValues" class="mx-25">
                        {{el.value}}
                        </small>
                        </div>
                        <small>{{Intl.NumberFormat('fa-IR').format(item.priceValue)}} تومان</small>
                      </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>

          </b-row>
          <b-row v-if="SelectedPrice">
            <b-col  cols="12">
              <div class="d-flex align-items-center">

                <strong class="h4 m-0">قیمت انتخاب شده : </strong>
                <div class="d-flex align-items-center  my-1">
                  <div class="d-flex align-items-center  flex-wrap mx-2">
                  <span v-for="(el,idx) in SelectedPrice.optionValues" class="mx-25 text-secondary">
                    {{el.value}}
                  </span>
                  </div>
                  <span class="font-weight-bold">{{Intl.NumberFormat('fa-IR').format(SelectedPrice.priceValue)}} تومان</span>
                </div>
              </div>
            </b-col>
            <b-col  cols="12">
              <b-form-group
                  label="پیوست عکس"
                  label-for="mc-country"
              >
                <b-form-file

                    accept=".jpg, .png, .gif"
                    placeholder="انتخاب عکس"
                    @input="makeBase64Pic"
                    ref="refInputEl"
                    drop-placeholder="Drop file here..."
                    multiple
                />
              </b-form-group>

            </b-col>
            <b-col v-if="Photos.length>0" class="my-3" cols="12">
              <div class="d-flex align-items-center justify-content-start  flex-wrap">
                <div v-for="(item,idx) in Photos" :key="idx" class="position-relative mx-2 bg-white rounded">
                  <feather-icon @click="Photos.splice(idx,1)" icon="TrashIcon" size="20"
                                class="text-danger position-absolute top-0 cursor-pointer"/>
                  <img :src="item.blob" style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
                </div>

              </div>

            </b-col>
            <b-col cols="12" class=" my-2">
              <b-button @click="SetMedias" variant="primary">ثبت</b-button>
            </b-col>
          </b-row>








        </b-tab>
        <b-tab v-if="ComputedProductType ===1" title="همگانی">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col md="4">
                  <small>قیمت</small>
                  <input v-model="formattedPrice" type="text" class="form-control">
                </b-col>
                <b-col md="4">
                  <small>تعداد</small>
                  <input v-model="SingleQty" type="number" class="form-control">
                </b-col>
                <b-col md="4" class="d-flex align-items-end">
                <b-button variant="primary" @click="AddPriceSingle" >ثبت قیمت</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-col>
    <div class="col-md-12 my-3 pt-3">
      <div class="row mt-3 BoxShadow ">
        <div class="col-md-3 px-0 border-left ">


        </div>
        <div class="col-md-9">
          <!--- Simple Product --->
          <div v-if="ComputedProductType ===1" id="Price"
               class="tabContent my-3        animate__animated animate__fadeIn "
               :class="getTabContentClassName(3)">

          </div>
        </div>
      </div>
    </div>

  </b-card>


</template>

<script>
import {GetAllOptions} from "@/libs/Api/optionValues";
import vSelect from "vue-select";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {BCard,BCol,BRow,BTabs, BTab,BButton,BFormRadioGroup,BDropdown,BDropdownItem, BFormInput,BFormCheckbox,BForm,BFormRadio, BFormGroup,BFormFile} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {getUserData} from "@/auth/utils";
import Helper from "@/libs/Helper";
export default {
  props:{
    Variable:{
      required:false,
      type:Boolean
    }
  },
  name: "AddPrice",
  components:{vSelect,BCard,BRow,BCol, BFormInput, BDropdown, BDropdownItem, BFormCheckbox, BForm, BTabs,BTab,BButton,BFormRadioGroup,BFormFile,BFormRadio,BFormGroup,AppCollapse,AppCollapseItem},
  created() {
    // this.$nuxt.$on('GetPrices', () => {
    //
    //   this.$emit('SendPrice', this.FinalPrice, this.SinglePrice, this.SingleQty, this.SelectedProductType)
    // })
  },
  data() {
    return {

      SelectedProductType: 0,
      SelectedTabId: 0,
      TabClasses: ['TabActive', 'TabContentActive'],
      AllOptions: [],
      SelectedOptions: [],
      SelectedOptionId: 0,
      CollectedData: null,
      VariableCounter: 1,
      Photos:[],
      SinglePrice: 0,
      SingleQty: 0,
      VariableCounts: [],
      FinalPrice: null,
      SelectedPrice:null

    }
  },
  async mounted() {
    let _this = this
    let getAllOptions = new GetAllOptions(_this)
    await getAllOptions.fetch(function (content) {
      console.log(content)
      _this.AllOptions = content

    }, function (error) {
      console.log(error)
    })

  },
  computed: {
    ComputedProductType() {
      return this.SelectedProductType
    },
    formattedPrice: {
      get: function () {
        return this.SinglePrice;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue && newValue != "") {
          // this.SinglePrice = newValue
          // .toString()
          // .replaceAll(",", "")
          // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.SinglePrice = newValue
              .toString()
              .replaceAll(",", "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // Remove all characters that are NOT number
          this.SinglePrice = this.SinglePrice.replace(
              /[a-zA-Z+*!@#$%^&*()_;:'"|<>/?{}\u0600-\u06EC/\-/\.]/g,
              ""
          );

        } else {
          this.SinglePrice = null;
        }

      },
    },
  },
  methods: {
    SetMedias(){
        const idx = this.SelectedPrice.idx
       this.Photos.forEach((item)=>{
         delete item.blob
      })
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `عملیات موفق`,
          icon: 'CheckIcon',
          variant: 'success',
          text: `عکس ها بارگذاری شدند`,
        },
      })
      this.FinalPrice[idx].photos = this.Photos
      this.SelectedPrice = null
      this.Photos = []
      this.FinalPrice.forEach((item)=>{
        delete item.idx
      })
      this.$emit('SendPrice', this.FinalPrice, this.SinglePrice, this.SingleQty, this.SelectedProductType)
    },
    async makeBase64Pic() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        priority: 1
      }

        Array.prototype.forEach.call(this.$refs.refInputEl.files, (element) => {
          if (element.size > 512000) {
            this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
          } else {
            f.push(element)
          }
        })
        f.forEach((element) => {
          const reader = new FileReader()
          reader.onload = (function (theFile) {
            return function () {
              let binaryData = reader.result
              tmpImage.base64 = window.btoa(binaryData)
              tmpImage.blob = URL.createObjectURL(element)
              const clone = {...tmpImage}
              that.Photos.push(clone)
              tmpImage.blob = ''
              tmpImage.priority = 2
              tmpImage.base64 = ''
            }
          })(f)
          reader.readAsBinaryString(element)
        })


      this.$refs.refInputEl.reset()


    },
    SetSelectedPrice(data,idx){
      this.SelectedPrice = data
      this.SelectedPrice['idx'] = idx
    },
    AddPrice() {
      if (this.VariableCounts.length > 0) {


        let prices = []
        let tmpPrice = {
          vendorUserId: getUserData().userId,
          priceValue: 0,
          quantity: 0,
          optionValues: [],
          photos:[]
        }

        let tmpOptionValue = {
          optionValueId: 0,
          optionId: 0,
          value: "",
          productId: 0,
          optionValueParentId: null,
          vendorUserId: getUserData().userId
        }

        this.VariableCounts.forEach((item, idx) => {
          this.CollectedData.forEach((el) => {
            tmpOptionValue.optionId = this.$refs[`FinalValue${idx}${el.ListId}`].value.optionId
            tmpOptionValue.optionValueId = this.$refs[`FinalValue${idx}${el.ListId}`].value.optionValueId
            tmpOptionValue.value = this.$refs[`FinalValue${idx}${el.ListId}`].value.value
            const c = {...tmpOptionValue}
            tmpPrice.optionValues.push(c)
            tmpOptionValue.optionId = 0
            tmpOptionValue.optionValueId = 0
            tmpOptionValue.value = ''
          })
          // parseInt(this.$refs[`Price${idx}`][0].value)
          // parseInt(this.$refs[`Qty${idx}`][0].value)
          tmpPrice.priceValue =parseInt(Helper.convertNumbers2English(this.$refs[`Price${idx}`][0].value))
          tmpPrice.quantity =parseInt(Helper.convertNumbers2English(this.$refs[`Qty${idx}`][0].value))

          const clone = {...tmpPrice}
          prices.push(clone)
          tmpPrice.priceValue = 0
          tmpPrice.quantity = 0
          tmpPrice.optionValues = []

        })
        this.FinalPrice = prices
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `لیست قیمت جدید ساخته شد`,
          },
        })
        this.VariableCounts = []
        this.VariableCounter = 1
        this.CollectedData = []
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `لطفا ابتدا چند متغیر تعریف کنید`,
          },
        })
      }

      this.$emit('SendPrice', this.FinalPrice, this.SinglePrice, this.SingleQty, this.SelectedProductType)
    },
    AddPriceSingle() {
      this.SinglePrice = this.SinglePrice.toString().replaceAll(',', '')
      this.$emit('SendPrice', this.FinalPrice, this.SinglePrice, this.SingleQty, this.SelectedProductType)
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `عملیات موفق`,
          icon: 'CheckIcon',
          variant: 'success',
          text: `قیمت جدید ساخته شد`,
        },
      })

      this.SelectedProductType = 0
    },
    DeleteVariable(idx) {
      this.VariableCounts.splice(idx, 1)
    },

    CheckValues(e, id, listId) {
      console.log(e)
      this.$refs[`FinalValue${id}${listId}`].value = e

    },
    AddVariable() {
      this.VariableCounts.push(this.VariableCounter)
      this.VariableCounter++
    },

    AddSingleValue(value, id) {

      this.$refs[`Category${id}`].value = value

    },
    CollectVariables() {
      if (this.SelectedOptions.length > 0) {
        let tmpDatas = []
        let listId = 1
        let tmpData = {
          ListId: 0,
          CollectedData: [],

        }
        let tmpOptionValue = {
          value: '',
          optionValueId: 0,
          optionId: 0,
          optionName: ''
        }
        this.SelectedOptions.forEach((item) => {
          if (this.$refs[`Category${item.optionId}`].value) {
            this.$refs[`Category${item.optionId}`].value.forEach((el) => {

              tmpOptionValue.value = el.value
              tmpOptionValue.optionValueId = el.optionValueId
              tmpOptionValue.optionId = item.optionId
              const c = {...tmpOptionValue}
              tmpData.CollectedData.push(c)
              tmpOptionValue.value = ''
              tmpOptionValue.optionValueId = 0
            })
            tmpData.ListId = listId
            const Clone = {...tmpData}
            tmpDatas.push(Clone)
            tmpData.CollectedData = []
            listId++
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `ابتدا چند ویژگی انتخاب کنید`,
              },
            })
          }
        })
        this.CollectedData = tmpDatas
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `متغیر ها به لیست متغیر ها اضافه شدند`,
          },
        })
        this.SelectedOptions = []
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `ابتدا چند ویژگی انتخاب کنید`,
          },
        })
      }
    },
    RemoveOption(idx) {
      this.SelectedOptions.splice(idx, 1)
    },
    AddOption() {
      let result = this.AllOptions.filter((element) => {
        return element.optionId === this.SelectedOptionId
      })
      this.SelectedOptions.push(result[0])
      result = []

      this.SelectedOptionId = 0
    },

    ChangeTab(id) {
      this.SelectedTabId = id
    },
    getTabClassName(id) {
      if (id === this.SelectedTabId) {
        return 'bg-lightGrey'
      } else {
        return ''
      }
    },
    getTabContentClassName(id) {
      if (id === this.SelectedTabId) {
        return this.TabClasses[1]
      } else {
        return ''
      }
    },

  }
}
</script>

<style scoped>
.SideBarPrice {
  min-height: 300px;

}

@media screen and (max-width: 767px) {
  .SideBarPrice {
    min-height: 100px !important;

  }
}
</style>
