<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
                label="نام محصول"
                label-for="mc-first-name"
            >
              <b-form-input
                  id="mc-first-name"
                  v-model="product.title"
                  placeholder="نام محصول"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="brands" cols="12" md="4">
            <b-form-group
                label="برند محصول"
                label-for="mc-city3"
            >
              <v-select
                  v-model="product.brandId"
                  :clearable="false"
                  :options="brands"
                  :reduce="title => title.brandId"
                  label="title"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <label for="">زمان تحویل محصول</label>

            <v-select
                v-model="product.productDeliveryDays"
                :options="deliveryDays"
                :reduce="name => name.id"
                class="per-page-selector bg-white d-inline-block w-100"
                label="name"
            />
          </b-col>
          <b-col class="my-1" cols="12" md="4">
            <b-form-group
                label="دسته بندی"
                label-for="mc-country"
            >
              <v-select
                  v-model="product.SelectedCategoryId"
                  :options="Categories"
                  :reduce="name => name.productCategoryId"
                  class="per-page-selector bg-white d-inline-block w-100"
                  label="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div v-if="Properties.length>0" class="row ">
              <label class="px-2">جزئیات پیش فرض این دسته بندی (اختیاری) : </label>
              <div v-for="item in Properties" :key="item.categoryPropertyId" class="col-md-12 my-2">
                <div class="row">
                  <div class="col-md-2"><span>{{ item.name }}</span></div>
                  <div class="col-md-3">
                    <input :ref="'Property'+item.categoryPropertyId" class="form-control"
                           placeholder="مقدار را وارد کنید"
                           type="text">
                  </div>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
                label="تاریخ و زمان انتشار"
                label-for=""
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <input
                    :id="`start${1}`"
                    :state="errors.length > 0 ? false:null"
                    :value="createJalaliDate(product.publishDate)"
                    class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                    placeholder="تاریخ و زمان انتشار"
                    type="text"
                />
              </validation-provider>
              <custom-date-picker
                  v-model="product.publishDate"
                  :element="`start${1}`"
                  :min="new Date(Date.now())"
                  auto-submit
                  class=""
                  color="#5c6bc0"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  input-format="YYYY-MM-DD HH:mm"
                  type="datetime"
              ></custom-date-picker>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    class="mb-2"
                    label="برچسب ها"
                    label-for="tagIds"
                >
                  <b-form-input
                      id="tagIds"
                      v-model="newTag.value"
                      placeholder="برچسب ها"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    class="mr-1 mt-2"
                    variant="success"
                    @click="addNewTag"
                >
                  افزودن برچسب جدید
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <div class="d-flex align-items-center gap-2">
              <b-badge v-for="(item,idx) in product.tags" :key="idx" class="mx-25 p-1 d-flex align-items-center"
                       variant="primary">
                <feather-icon class="mx-25  text-danger cursor-pointer" icon="XIcon"
                              @click="product.tags.splice(idx,1)"></feather-icon>
                <span class="mt-25">
                {{ item.value }}
                </span>
              </b-badge>
            </div>
          </b-col>
          <b-col cols="12 my-25">
            <label>جزئیات محصول : </label>
            <b-row>
              <b-col cols="12" md="3">
                <label>نام</label>
                <input v-model="ProductProperty.ProductPropertyName" class="form-control" placeholder="مثال جنس"
                       type="text">
              </b-col>
              <b-col cols="12" md="3">
                <label>مقدار</label>
                <input v-model="ProductProperty.ProductPropertyValue" class="form-control" placeholder="مثال کتان"
                       type="text">
              </b-col>
              <b-col class="my-2" md="2">
                <b-button variant="primary" @click="addNewProperty">افزودن</b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="mb-2" cols="12">
            <div class="d-flex flex-wrap  align-items-center">
              <b-badge v-for="(item,idx) in productProperties " :key="idx" class="mx-25" variant="primary">
                <feather-icon
                    class="  text-danger cursor-pointer"
                    icon="TrashIcon"
                    @click="deleteProperty(idx)"
                />
                <span class="mx-1">{{ item.name }}:{{ item.value }}</span>
              </b-badge>

            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="پیوست عکس"
                label-for="mc-country"
            >
              <b-form-file

                  ref="refInputEl"
                  accept=".jpg, .png, .gif"
                  drop-placeholder="Drop file here..."
                  multiple
                  placeholder="انتخاب عکس"
                  @input="makeBase64Pic"
              />
            </b-form-group>

          </b-col>
          <b-col v-if="ProductPhotos.length>0" class="my-3" cols="12">
            <div class="d-flex align-items-center justify-content-start  flex-wrap">
              <div v-for="(item,idx) in ProductPhotos" :key="idx" class="position-relative mx-2 bg-white rounded">
                <feather-icon class="text-danger position-absolute top-0 cursor-pointer" icon="TrashIcon" size="20"
                              @click="ProductPhotos.splice(idx,1)"/>
                <img :src="item.blob" alt="" class="rounded" style="width: 10rem;height: 10rem;object-fit: contain">
              </div>

            </div>

          </b-col>
          <b-col cols="12">
            <addPrice @SendPrice="CallPrices"></addPrice>
          </b-col>
          <b-col
              cols="12"
          >
            <b-form-group
                label="توضیحات"
                label-for="summary"
            >
                <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent"></ckEditorMain>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->

        </b-row>
      </b-form>

    </b-card>
    <b-card class="blog-edit-wrapper">
      <h2>سئو</h2>

      <b-form class="mt-2">
        <b-row>
          <b-col class="" md="6">
            <b-form-group
                class=""
                label="عنوان"
                label-for="blog-edit-title"
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="product.seoTitle"
              />
            </b-form-group>
            <span :class="{'text-danger':product.seoTitle.length>60}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                product.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    class=""
                    label="آدرس"
                    label-for="blog-edit-seourl"
                >
                  <b-form-input
                      id="blog-edit-seourl"
                      ref="myInput"
                      v-model="allCharactersToDash"
                      v-on:focus="$event.target.select()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    class="mt-2 ml-2"
                    variant="warning"
                    @click="copyText"
                >
                  کپی
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col class="mt-2" cols="12">
            <b-form-group
                class="mb-2"
                label="توضیحات"
                label-for="blog-content"
            >
              <b-form-textarea
                  v-model="product.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span :class="{'text-danger':product.seoDescription.length>160}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                product.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>

          <b-card class="mt-1 w-100">

            <h2>نحوه نمایش در گوگل</h2>

            <div class="col-md-12 my-3">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ product.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span style="overflow-wrap: anywhere">{{ product.seourl.replaceAll(' ', '-') }}/</span>
                    <span>
                             {{ 'https://enzamode.ir/plaza/' }}
                        <span class="serp-arrow"></span>
                      </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ product.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
                <div class="serp-title">
                  <h6>
                    {{ product.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ product.seourl.replaceAll(' ', '-') }}/</small>
                  <small>
                    {{ 'https://enzamode.ir/plaza/' }}
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ product.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-card>

          <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-1"
                type="button"
                variant="primary"
                @click="CreateProduct"
            >
              ثبت نهایی
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BBadge,
  BFormCheckbox,
  BOverlay,
  BForm,
  BButton,
  BMedia,
  BCard,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {quillEditor} from 'vue-quill-editor'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import vSelect from 'vue-select'
import {required} from '@validations'
import addPrice from '@/views/components/addPrice.vue'
import {GetPropertiesByCategoryId} from "@/libs/Api/CategoryProperties";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {GetAllProductCategory} from "@/libs/Api/productCategory";
import {getUserData} from "@/auth/utils";
import {CreateProduct} from "@/libs/Api/product";
import Helper from "@/libs/Helper";
import {GetAllBrand} from "@/libs/Api/brands";
import ckEditorMain from "@/views/components/ckEditorMain";

export default {
  title: 'افزودن محصول  - پنل ادمین انزا مد',
  components: {
    ToastificationContent,
    BRow,
    BCol,
    BBadge,
    BOverlay,
    addPrice,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    quillEditor,
    vSelect,
    BMedia,
    BCard,
    BFormInput,
    ckEditorMain,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
  },
  data() {
    return {
      Categories: [],
      showOverlay: false,
      base64ImageSrc: '',
      FinalPriceV: null,
      SinglePRiceV: null,
      SingleQty: null,
      PType: null,
      newTag: {
        value: "",
        type: 1,
        objectId: 0,
        createDate: new Date(Date.now())
      },
      ProductProperty: {
        ProductPropertyName: '',
        ProductPropertyValue: ''
      },
      ProductPhotos: [],
      selectedDeliveryDay: 0,
      deliveryDays: [
        {
          name: '1 ماهه',
          id: 30
        },
        {
          name: '2 ماهه',
          id: 60
        },
        {
          name: '3 ماهه',
          id: 90
        },
        {
          name: '6 ماهه',
          id: 180
        },
      ],
      productProperties: [],
      Properties: [],
      variablePrices: false,
      brands: null,
      product: {
        seourl: '',
        seoTitle: '',
        seoDescription: '',
        title: '',
        summary: '',
        productDeliveryDays: 0,
        publishDate: '',
        description: '',
        brandId: 0,
        SelectedCategoryId: null,
        tags: [],
      },


    }
  },
  async mounted() {
    await this.GetAllCategories();
    await this.getAllBrands();
  },
  watch: {
    'product.SelectedCategoryId': function (val) {
      this.GetCategoryProperties(val);
    }
  },

  computed: {
    allCharactersToDash: {
      get: function () {
        return this.product.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.product.seourl = newValue
          // Remove all characters that are NOT number
          this.product.seourl = this.product.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.product.seourl = this.product.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.product.seourl === "") {
          this.product.seourl = null;
        }
      },
    },
  },
  methods: {
    setEditorContent(content) {
      this.product.description = content

    },
    addNewTag() {
      this.product.tags.push(this.newTag)
      this.newTag = {
        value: "",
        type: 1,
        objectId: 0,
        createDate: new Date(Date.now())
      }
    },
    async getAllBrands() {
      let _this = this
      let getAllBrand = new GetAllBrand(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0,
      }
      getAllBrand.setParams(data)
      await getAllBrand.fetch(function (content) {
        _this.brands = content.data.requests

      }, function (error) {
        console.log(error)
      })
    },
    deleteProperty(idx) {
      this.productProperties.splice(idx, 1)
    },
    createJalaliDate(param) {
      if (param !== '') {

        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    addNewProperty() {
      //Get Properties
      let tmpProperty = {
        name: '',
        value: ''
      }
      tmpProperty.name = this.ProductProperty.ProductPropertyName
      tmpProperty.value = this.ProductProperty.ProductPropertyValue
      const clone = {...tmpProperty}
      this.productProperties.push(clone)
      tmpProperty.name = ''
      tmpProperty.value = ''
      this.ProductProperty =
          {
            ProductPropertyName: '',
            ProductPropertyValue: ''
          }
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand('copy');
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس محصول کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    CallPrices(FinalPriceV, SinglePriceV, SingleQty, PType) {
      this.FinalPriceV = FinalPriceV
      this.SinglePRiceV = SinglePriceV
      this.SingleQty = SingleQty
      this.PType = PType
    },


    async CreateProduct() {
        this.$refs.editorContent.emitDataToParent()
      if (this.product.title === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `لطفا نام محصول را وارد کنید`,
          },
        })
      } else {
        if (!this.PType) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات ناموفق`,
              icon: 'CloseIcon',
              variant: 'danger',
              text: `لطفا نوع محصول را انتخاب کنید`,
            },
          })
        } else {
          if (this.PType === 1 && !this.SinglePRiceV && !this.SingleQty) {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `لطفا قیمت مورد نظر را ثبت کنید`,
              },
            })

          } else if (this.PType === 2 && !this.FinalPriceV) {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `لطفا قیمت های مورد نظر را ثبت کنید`,
              },
            })
          } else if (this.product.seourl === '' || this.product.seoTitle === '' || this.product.seoDescription === '') {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `لطفا داده های اولیه سئو را ثبت کنید`,
              },
            })
          } else {
            //Set Image
            let tmpPhotos = []
            let tmpPhoto = {
              base64: "",
              priority: 0
            }

            if (this.ProductPhotos.length > 0) {
              this.ProductPhotos.forEach((item) => {
                tmpPhoto.base64 = item.base64
                tmpPhoto.priority = item.priority
                const clone = {...tmpPhoto}
                tmpPhotos.push(clone)
                tmpPhoto.base64 = ''
                tmpPhoto.priority = 0
              })
              const idx = tmpPhotos.findIndex(e => e.priority === 1)
              if (idx >= tmpPhotos.length) {
                let k = 0 - tmpPhotos.length + 1;
                while (k--) {
                  tmpPhotos.push(undefined);
                }
              }
              tmpPhotos.splice(0, 0, tmpPhotos.splice(idx, 1)[0]);
            }

            // Set Product Type
            if (this.PType === 2) {
              this.variablePrices = true
            }
            // Set Prices
            let tmpPrices = []
            let SinglePrice = {
              vendorUserId: getUserData().userId,
              priceValue: parseInt(this.SinglePRiceV),
              quantity: parseInt(this.SingleQty),
            }
            if (this.PType === 1) {
              tmpPrices.push(SinglePrice)
            } else if (this.PType === 2) {
              tmpPrices = this.FinalPriceV
            }
            //Get Properties
            let tmpProperty = {
              name: '',
              value: ''
            }
            this.Properties.forEach((item) => {
              if (this.$refs['Property' + item.categoryPropertyId][0].value === '') {
              } else {
                tmpProperty.name = item.name
                tmpProperty.value = this.$refs['Property' + item.categoryPropertyId][0].value
                const clone = {...tmpProperty}
                this.productProperties.push(clone)
                tmpProperty.name = ''
                this.$refs['Property' + item.categoryPropertyId][0].value = ''
                tmpProperty.value = ''
              }
            })
            // this.$refs.editorContent.getEditorContent()
            let _this = this;
            _this.showOverlay = true
            let createProduct = new CreateProduct(_this);
            let data = {
              title: this.product.title,
              summary: this.product.summary,
              description: this.product.description,
              seourl: this.product.seourl,
              seoTitle: this.product.seoTitle,
              seoDescription: this.product.seoDescription,
              productCategoryId: this.product.SelectedCategoryId,
              photos: tmpPhotos,
              expireDate: '',
              isInStock: true,
              isTolerated: false,
              publishDate: this.product.publishDate,
              productDeliveryDays: 0,
              variablePrices: this.variablePrices,
              brandId: this.product.brandId,
              country: '',
              createDate: new Date(Date.now()),
              properties: this.productProperties,
              prices: tmpPrices,
              tags: this.product.tags
            }
            createProduct.setData(data);
            await createProduct.fetch(function (content) {
              _this.$toast({
                component: ToastificationContent,
                position: 'bottom-center',
                props: {
                  title: `عملیات موفق`,
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `محصول ساخته شد.`,
                },
              })
              _this.$router.push('/pages/products/ProductsList')
              _this.showOverlay = false
            }, function (error) {
              console.log(error)
              _this.showOverlay = false

            })
          }
        }
      }

    },
    async GetCategoryProperties(val) {
      let _this = this
      _this.showOverlay = true
      let getAllProductCategory = new GetPropertiesByCategoryId(_this)
      let data = {
        categoryId: val
      }
      getAllProductCategory.setParams(data)
      await getAllProductCategory.fetch(function (content) {
        _this.showOverlay = false
        _this.Properties = []
        content.forEach((item) => {
          _this.Properties.push(item)

        })

      }, function (error) {
        console.log(error)
        _this.showOverlay = false
      })

    }
    ,
    async makeBase64Pic() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        priority: 1
      }
      if (this.$refs.refInputEl.files.length > 4 || this.ProductPhotos.length > 4) {
        this.$toast.error('میتوانید تا 5 عکس را همزمان اضافه کنید')
      } else {
        Array.prototype.forEach.call(this.$refs.refInputEl.files, (element) => {
          if (element.size > 512000) {
            this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
          } else {
            f.push(element)
          }
        })
        f.forEach((element) => {
          const reader = new FileReader()
          reader.onload = (function (theFile) {
            return function () {
              let binaryData = reader.result
              tmpImage.base64 = window.btoa(binaryData)
              tmpImage.blob = URL.createObjectURL(element)
              const clone = {...tmpImage}
              that.ProductPhotos.push(clone)
              tmpImage.blob = ''
              tmpImage.priority = 2
              tmpImage.base64 = ''
            }
          })(f)
          reader.readAsBinaryString(element)
        })

      }
      this.$refs.refInputEl.reset()
    }
    ,
    async GetAllCategories() {
      let _this = this
      let getAllProductCategory = new GetAllProductCategory(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0
      }
      getAllProductCategory.setParams(data)
      await getAllProductCategory.fetch(function (content) {
        _this.Categories = content.productCategories

      }, function (error) {
        console.log(error)
      })
    }
    ,
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
.ql-container {
  height: 250px !important;
}
</style>
